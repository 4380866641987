import React from 'react'
import './header.css'
import ME from '../../assets/me2.jpg'
import CTA from './CTA'


const Header = () => {
  return (
    <header>
      <div id='home' className="container header__container">
        <div className="header__container-top">
          <h1>Mitja Immonen</h1>
          <h4 className="text-light">Software Developer</h4>
        </div>
      </div>

      <div className="header-separator"></div>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>

        <div className="about__content">

          <h4>Hi there!</h4>
          <p>
            My name is Mitja. I'm a tech nerd with a bit of an artistic background.
            Currently, I am employed as a Software Developer at WordDive, mainly working with Flutter.
          </p>
          <p>
            In my spare time, I work on personal projects, like apps and games, and try to learn new things.
            One day, I hope to become a self-sufficient software developer.
          </p>
          <CTA />

        </div>
      </div>
    </header>
  )
}

export default Header